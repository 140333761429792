import { Translations } from './TranslationType';
import { Currency } from './../enums/Currency';
const nb: Translations = {
  meta: {
    title: 'Jetpak',
    metaDescription: '',
    appName: 'Send pakke',
    language: 'Norsk',
    languageCode: 'nb-NO',
    currency: Currency.NOK,
    email: 'kundeservice@jetpak.no',
    phone: {
      label: '+47 915 09 899',
      number: '+4791509899',
    },
  },
  packageTypes: { error: 'Error loading package types' },
  deliveryPage: {
    title: 'Transportalternativ',
    tab: 'Transportalternativ',
    nextStep: 'Fortsett til pakkens innhold',
    addressTitle: 'Angi detaljer',
    preamble: '',
    fillInTheFieldsAboveToSeeDeliveryAlternatives:
      'Fyll ut feltene ovenfor for å se transportmuligheter.',
    newAlternativesLoaded: 'Nye alternativer tilgjengelige',
    loadingAlternatives: 'Loading delivery alternatives',
    errorLoadingAlternatives: 'Feil ved lasting av alternativer',
    alternativeSelected: 'Levering valgt',
    noAlternativesFound:
      'Finner ingen leveringsalternativer, oppdater søkekriteriene og prøv igjen.',
    invalidPackages: 'Ugyldig inndata for pakkedimensjon/vekt',
    excessiveVolume:
      'Leveransen er for stor for online bestilling. Vennligst kontakt kundeservice',
    excessiveWeight:
      'For tung levering for online bestilling. Vennligst kontakt kundeservice',
    selectDeliveryOptions: 'Velg leveringsalternativ',
  },
  packageInformationPage: {
    title: 'Pakkeinnhold',
    tab: 'Pakkeinnhold',
    previousStep: 'Tilbake',
    nextStep: 'Fortsett til avsender og mottaker',
    preamble: 'Skriv hva forsendelsen inneholder',
    titleContentExample: 'Beskriv innholdet',
    packageContentLabel: 'Beskriv innholdet',
    placeHolderExample: 'Hodetelefoner, mobiltelefon og bøker...',
    seeExamples: 'Se eksempler',
    writeLike: 'Dette er ok',
    dontWrite: 'Ikke skriv',
    howToWrite: [
      {
        correct: 'Hodetelfoner, mobiltelefon',
        incorrect: 'Elektronikk',
      },
      {
        correct: 'Sportsbag',
        incorrect: 'Varer',
      },
      {
        correct: 'Pass',
        incorrect: 'Materiale',
      },
      {
        correct: 'PC',
        incorrect: 'Deler',
      },
    ],
  },
  senderAndConsigneePage: {
    title: 'Avsender og mottaker',
    tab: 'Avsender og mottaker',
    previousStep: 'Tilbake',
    nextStep: 'Fortsett til oppsummering',
    nextStepCustoms: 'Fortsett til tollinformasjon',
    preamble: 'Legg inn informasjon for avsender og mottaker',
  },
  customsPage: {
    title: 'Tollinformasjon',
    tab: 'Toll',
    previousStep: 'Tilbake',
    nextStep: 'Fortsett til oppsummering',
    preamble:
      'Firmaet må ha en EORI-nummer for å importere og eksportere varer (EORI = Økonomisk operatørregistrering og identifikasjon).',
    eori: {
      linkText: 'Les mer om EORI-nummer',
      linkUrl:
        'https://www.toll.no/no/bedrift/transport-og-tollager/hva-er-eori-nummer/',
      description: '',
      title: 'EORI',
    },
    customsValue: 'Tollverdi',
    customsValueDescription:
      'Tollverdien er basert på leverandørenes pris på varene. Informasjon om andre kostnader (ekskl. kostnaden for varene)som er inkludert i tollverdien kan finnes på ',
    customsValueLinkText: 'Tollverdi',
    customsValueLinkUrl:
      'https://www.toll.no/no/bedrift/import/beregning/tollverdi/tollverdiveileder',
    customsInvoiceNumber: 'Toll fakturanummer',
    customsInvoiceNumberDescription:
      'Fakturaen må følge forsendelsen. Hvis innholdet skal selges i mottakerlandet, bør du bruke en kommersiell faktura. Dersom produktet ikke skal selges, brukes proformalaen i produktene.',
    customsInvoiceNumberLinkDescription: 'Nedlastinger:',
    customsInvoiceNumberLinkText1: 'Kommersiell faktura',
    customsInvoiceNumberLinkUrl1:
      'https://jetpak.com/files/5714/8613/0709/Jetpak_Commercial_Invoice.pdf',
    customsInvoiceNumberLinkText2: 'Proforma faktura',
    customsInvoiceNumberLinkUrl2:
      'https://jetpak.com/files/8214/8613/0638/Jetpak_Proforma_Invoice.pdf',
    customsNumber: 'Toll nummer',
    customsNumberDescription1:
      'Varenummer bestemmer hvilken tollsats som skal brukes for produktet og består av ti siffer. Du kan søke etter varekoden på tollmyndighetenes nettsted under overskriften.',
    customsNumberDescription2:
      'På Tollfaktura skriver du varekoden i tilsvarende felt.',
    customsContent: '',
    shipperEori: 'Eori shipper',
    consigneeEori: 'Eori consignee',
    commodityCodeLength: 'Minimum 6 characters',
  },
  paymentPage: {
    title: 'Oppsummering',
    tab: 'Oppsummering',
    preamble: '',
    previousStep: 'Tilbake',
    previousStepCustoms: 'Gå tilbake til toll',
    nextStepByCard: 'Fortsett til betaling',
    nextStepByInvoice: 'Betal med faktura',
    paymentOptions: 'Betalingsalternativer',
    paymentReference: 'Betalingsreferanse',
    paymentReferencePlaceholder:
      'For din referanse, dvs. et innkjøpsordrenummer',
    signIntoPayByInvoice: 'Du må logge inn for å kunne betale med faktura.',
    invoiceNotAllowed:
      'Betaling via faktura er ikke tillatt. Kontakt kundeservice for mer informasjon.',
    campaignCode: 'Kampanjekode    ',
    campaignCodeApplied: 'Kampanjekode lagt til',
    invalidCampaignCode: 'Ugyldig kampanjekode',
    apply: 'Bruk kampanjekode',
  },
  loginPage: {
    title: 'Logg inn',
  },
  registerPage: {
    title: 'Opprett en konto',
    buttonText: 'Opprett konto',
    continueButtonText: 'Fortsett',
    previousButtonText: 'Tilbake',
    customerTypeHeader: 'Jeg ønsker å',
    existingCustomerHeader: 'Har din organisasjon et kundenummer hos oss?',
    loginText: 'Har du en konto?',
    fields: {
      existingNo: 'Ny kunde',
      existingYes: 'Det finnes allerede et kundenummer med Jetpak',
      customerTypeCompany: 'Bedriftskonto',
      customerTypePrivate: 'Privat konto',
      orgName: 'Navn på bedrift',
      orgNumber: 'Organisasjonsnummer',
      vatNumber: 'MVA nummer',
      customerNumber: 'Kundenummer',
      firstName: 'Fornavn',
      lastName: 'Etternavn',
      email: 'E-post',
      cellPhone: 'Mobil',
      phone: 'Telefon',
      street: 'Gate',
      streetNumber: 'Gate nummer',
      city: 'By',
      postalCode: 'Postnummer',
      country: 'Land',
    },
    invalidNbr: 'Ikke et gyldig kontonummer',
    nonExistantNbr: 'Kundenummer ikke funnet',
  },
  orderConfirmationPage: {
    title: 'Booking bekreftelse',
    preamble: '',
    yourBookingNumber: 'Ditt bestillingsnummer (AWB)',
  },
  orderSummary: {
    title: 'Sammendrag',
    preamble: 'Få den samlede oversikten over bestillingen din',
  },
  advise: {
    title: 'Booking- og leveransebekreftelse',
    description:
      'Velg om du ønsker din bestillingsbekreftelse og leveringsvarsler via sms og/eller e-post',
    type: 'Type',
    method: 'Metode',
    bookingConfirmation: 'Bookingbekreftelse',
    deliveryConfirmation: 'Leveringbekreftelse',
    email: 'E-post',
    textMessage: 'SMS',
    addMoreNotifications: 'Legg til flere varslinger',
  },
  requiredField: 'Dette feltet er obligatorisk',
  timeHasPassed: 'Tid utløpt',
  invalidEmailAddress: 'E-post adressen må være lik formatet email@example.com',
  shipper: 'Avsender',
  consignee: 'Mottaker',
  shipperAddress: 'Adresse avsender',
  consigneeAddress: 'Adresse mottaker',
  shipperAddressPlaceholder: 'Søk etter avsenderadresse',
  consigneeAddressPlaceholder: 'Søk etter mottakeradresse',
  pickUpAndDelivery: 'Opphenting/Levering',
  pickUp: 'Tid for opphenting',
  delivery: 'Tid for levering',
  earliestPickup: 'Tidligst opphenting',
  latestDelivery: 'Senest levering',
  shippingDetails: 'Fraktdetaljer',
  select: 'Velg',
  selected: 'Valgt',
  selectDeliveryOptions: 'Velg leveringsalternativer',
  price: 'Pris',
  date: 'Dato',
  time: 'Tid',
  package: 'Pakke',
  add: 'Legg til',
  addMore: 'Legg til mer',
  remove: 'Fjern',
  addPackage: 'Legg til pakke',
  removePackage: 'Fjern pakke',
  yourPackages: 'Dine pakker',
  addMorePackages: 'Legg til flere pakker',
  whatIsTheSizeOfYourPackage: 'Hvor stor er pakken?',
  weight: 'Vekt',
  width: 'Bredde',
  height: 'Høyde',
  length: 'Lengde',
  quantity: 'Antall',
  quantityPcs: 'Antall pakker',
  pcs: 'stk',
  added: 'Lagt til',
  vat: 'Moms',
  withoutVat: 'eksl. moms',
  withVat: 'inkl. moms',
  total: 'Totalt',
  clear: 'Tøm',
  inKilogram: 'i kilo',
  discount: 'Rabatt',
  inCm: 'i cm',
  today: 'Idag',
  tomorrow: 'I morgen',
  currency: 'Valuta',
  countryOfOrigin: 'Opprinnelsesland',
  username: 'Brukernavn',
  password: 'Passord',
  currentPassword: 'Nåværende passord',
  newPassword: 'Nytt passord',
  newPassword2: 'Gjenta passord',
  passwordResetLinkCreated:
    'En lenke for tilbakestilling av passord har blitt sendt til deg på e-post',
  signIn: 'Logg inn',
  signOut: 'Logg ut',
  signedIn: 'Logget inn',
  signedOut: 'Logget ut',
  signInErrorMessage: `Vi kunne ikke finne din Jetpak-konto`,
  forgotPassword: 'Glemt passord?',
  resetPassword: 'Tilbakestille passord',
  changePassword: 'Bytt passord  ',
  passwordReset: 'Passordet ditt har blitt tilbakestilt',
  passwordChanged: 'Ditt passord har blitt endret',
  register: 'Opprett en konto',
  loading: 'Loading',
  company: 'Firmanavn',
  name: 'Navn',
  phone: 'Telefon',
  messageToDriver: 'Melding til sjåfør',
  messageToDriverExample: 'Portkode, annen informasjon...',
  paymentProcessing: 'Vennligst vent mens vi behandler din forespørsel',
  invoice: 'Faktura',
  creditcard: 'Kredittkort',
  creditCardHoldersName: 'Eierens navn på kredittkort',
  email: 'E-post',
  country: 'Land',
  postalAddress: 'Postadresse',
  postalCode: 'Postnummer',
  postalArea: 'By',
  open: 'Åpen',
  close: 'Lukk',
  or: 'eller',
  help: 'Hjelp  ',
  helpPage: {
    getSupportTicket: 'Opprett support forespørsel',
    content1:
      'Hvis du har problemer med bestillingen din, opprett en support forespørsel.',
    content2:
      'Ring vårt kundeservicesenter og oppgi support forespørselnummeret.',
    supportNumber: 'Support forespørselnummer:',
    yourTicketNumber: 'Forespørselnummeret ditt er',
  },
  welcome: 'Velkommen',
  youAreloggedInAs: 'Du er logget inn som',
  carbonDioxideMessage: 'Utslipp av karbondioksid',
  createNewBooking: 'Opprett ny booking',
  addressBook: 'Adresse bok',
  profile: 'Profil',
  addressBookModal: {
    title: 'Adresse bok',
    pickAddress: 'Velg en adresse nedenfor:',
    noSavedAddresses: 'Det finnes ingen lagrede adresser.',
    name: 'Navn',
    street: 'Gate',
    streetNumber: 'Gate nummer',
    city: 'By',
    postalCode: 'Postnummer',
    country: 'Land',
    addAnother: 'Add another',
    addressName: 'Navn',
    addressSaved: 'Adresse lagret',
    newAddress: 'Ny adresse',
    editAddress: 'Rediger address',
    showing: (showing, total) =>
      `Viser ${showing} av ${total} lagrede adresser`,
  },
  addressValidationErrors: {
    missingStreet: 'Manglende gateadresse',
    missingStreetNumber: 'Manglende gateadresse',
    verificationError: 'Adressen kunne ikke verifiseres',
    error: 'Adressen kunne ikke verifiseres',
  },
  orderFailed: {
    cancelled: 'Betaling avbrutt',
    error: 'Feil ved opprettelse av ordre',
    declined: 'Kortet ditt ble avvist',
    pickupMissed:
      'Opphentingstiden ikke tilgjengelig. Søk etter nye transportalternativer.',
  },
  support: {
    needHelp: 'Trenger du hjelp?',
    contactOurSupport: 'Kontakt kundeservice.',
  },
  // parcelTypes: {
  //   envelope: 'Brev',
  //   bag: 'Pose',
  //   smallBox: 'Liten pakke',
  //   largeBox: 'Stor pakke',
  //   pallet: 'Pall',
  //   other: 'Annen',
  // },
  unitTypes: {
    kg: 'kg',
    cm: 'cm',
  },
  termsAndPolicies: {
    dangerousGoods: {
      label: 'Jeg har lest og forstått restriksjonene for',
      urlLabel: 'transporting dangerous goods',
      url: 'https://jetpak.com/no/vilkar/viktig-informasjon/farlig-gods',
    },
    privacyPolicy: {
      label: `Jeg har lest og forstått Jetpaks
`,
      urlLabel: 'retningslinjer for personvern',
      url: 'https://jetpak.com/no/vilkar/privacy-statement/',
    },
    termsAndConditions: {
      label: `Jeg godtar herved Jetpaks
`,
      urlLabel: 'vilkår og betingelser',
      url: 'https://jetpak.com/no/vilkar/viktig-informasjon/',
    },
  },
  days: {
    monday: 'Mandag',
    tuesday: 'Tirsdag',
    wednesday: 'Onsdag',
    thursday: 'Torsdag',
    friday: 'Fredag',
    saturday: 'Lørdag',
    sunday: 'Søndag',
  },
  insurance: {
    title: 'Forsikring',
    description:
      'For å forsikre varene dine, vennligst skriv inn verdien for å beregne prisen. Prisen vil bli lagt til bestillingen din.',
    labelToggle: 'Legg til forsikring',
    labelInput: 'Forsikringsverdi',
    fee: 'Forsikring avgift',
    insurancePremium: (rate, minPrice, currency) =>
      `Forsikringspremien er ${rate}% av produktverdien, men minst ${minPrice} ${currency}. Kostnader for egenandeler forekommerkommer ikke tillegg.`,
  },
  save: 'Spara',
};
export default nb;

import { Translations } from './TranslationType';
import { Currency } from './../enums/Currency';

const en: Translations = {
  meta: {
    title: 'Jetpak',
    metaDescription: '',
    appName: 'Send package',
    language: 'English',
    languageCode: 'en-GB',
    currency: Currency.EUR,
    email: 'kundservice@jetpak.se',
    phone: {
      label: '+46 077-570 00 00',
      number: '+460775700000',
    },
  },
  packageTypes: { error: 'Error loading package types' },
  deliveryPage: {
    title: 'Delivery alternatives',
    tab: 'Delivery alternatives',
    nextStep: 'Continue to package content',
    addressTitle: 'Where do you want send?',
    preamble: '[Missing content]',
    fillInTheFieldsAboveToSeeDeliveryAlternatives:
      'Fill in the fields above to see delivery options.',
    newAlternativesLoaded: 'New alternatives available',
    loadingAlternatives: 'Loading delivery alternatives',
    errorLoadingAlternatives: 'Error loading alternatives',
    alternativeSelected: 'Delivery selected',
    noAlternativesFound:
      'No delivery alternatives found, update search criteria and try again.',
    invalidPackages: 'Invalid input for package dimension/weight',
    excessiveVolume:
      'Delivery too large for online booking. Please contact customer support',
    excessiveWeight:
      'Delivery too heavy for online booking. Please contact customer support',
    selectDeliveryOptions: 'Select delivery alternative',
  },
  packageInformationPage: {
    title: 'The content of the package',
    tab: 'Package content',
    previousStep: 'Go back',
    nextStep: 'Continue to shipper & consignee',
    preamble: 'Write what your shipment contains',
    titleContentExample: 'Describe your content like this',
    packageContentLabel: 'Describe the content',
    seeExamples: 'See examples',
    placeHolderExample: 'Headphones, mobile phone and books...',
    writeLike: 'This is ok',
    dontWrite: 'Do not write',
    howToWrite: [
      { correct: 'Headphones, mobile phone', incorrect: 'Electronics' },
      // { correct: '[Missing content]', incorrect: '[Missing content]' },
    ],
  },
  senderAndConsigneePage: {
    title: 'Sender and recipient',
    tab: 'Sender and recipient',
    previousStep: 'Go back',
    nextStep: 'Continue to summary',
    nextStepCustoms: 'Continue to customs information',
    preamble: 'Enter information for the sender and recipient',
  },
  customsPage: {
    title: 'Customs information',
    tab: 'Customs',
    previousStep: 'Go back',
    nextStep: 'Continue to summary',
    preamble:
      'Your company must have a EORI number in order to import and export goods (EORI = Economic Operator Registration and Identification).',
    eori: {
      linkText: 'Read more about EORI numbers',
      linkUrl:
        'https://www.tullverket.se/eng/business/eorieconomicoperatorregistrationandidentification/applicationforeorinumber.4.7df61c5915510cfe9e76040.html',
      description: '',
      title: 'EORI',
    },
    customsValue: 'Customs value',
    customsValueDescription:
      'Customs value is based on the suppliers price of the goods. Information about other costs (excluding the cost of the goods)that are included in the customs value can be found at ',
    customsValueLinkText: 'Import charges',
    customsValueLinkUrl:
      'https://www.tullverket.se/en/startpage/business/internationalbusiness/importinggoodstoswedenfromcountriesoutsidetheeu/importcharges.4.7df61c5915510cfe9e75e5d.html',
    customsInvoiceNumber: 'Customs Invoice Number',
    customsInvoiceNumberDescription:
      'An invoice must accompany the shipment. If the content is to be sold in the receiving country, then you should use a commerciela invoice. If the product is not to be sold, then use the proforma invoice.',
    customsInvoiceNumberLinkDescription: 'Downloads:',
    customsInvoiceNumberLinkText1: 'Commercial invoice',
    customsInvoiceNumberLinkUrl1:
      'https://jetpak.com/files/5714/8613/0709/Jetpak_Commercial_Invoice.pdf',
    customsInvoiceNumberLinkText2: 'Proforma invoice',
    customsInvoiceNumberLinkUrl2:
      'https://jetpak.com/files/8214/8613/0638/Jetpak_Proforma_Invoice.pdf',

    customsNumber: 'Customs Number',
    customsNumberDescription1:
      'Commodity code decides which customs rate is used for the product and consists of a ten digit number. You can search for the commodity code on the Customs Authorities web site under headline.',
    customsNumberDescription2:
      'On the Customs invoice write in the commodity code in the corresponding field.',
    customsContent: '',
    shipperEori: 'Eori shipper',
    consigneeEori: 'Eori consignee',
    commodityCodeLength: 'Minimum 6 characters',
  },
  paymentPage: {
    title: 'Summary',
    tab: 'Summary',
    preamble: '[Missing content]',
    previousStep: 'Go back',
    previousStepCustoms: 'Go back to customs',
    nextStepByCard: 'Continue to payment',
    nextStepByInvoice: 'Pay by Invoice',
    paymentOptions: 'Payment options',
    paymentReference: 'Payment reference',
    paymentReferencePlaceholder:
      'For your reference, i.e. a purchase order number',
    signIntoPayByInvoice: 'You must sign in to be able to pay by invoice.',
    invoiceNotAllowed:
      'Payment by invoice is currently not allowed. Please contact support for more information.',
    campaignCode: 'Campaign code',
    campaignCodeApplied: 'Campaign code added',
    invalidCampaignCode: 'Invalid campaign code',
    apply: 'Apply',
  },
  loginPage: {
    title: 'Sign in',
  },
  registerPage: {
    title: 'Create an account',
    buttonText: 'Create Account',
    continueButtonText: 'Continue',
    previousButtonText: 'Back',
    customerTypeHeader: 'I want to',
    existingCustomerHeader:
      'Does an account already exist with us in your organization?',
    loginText: 'Do you have an account?',
    fields: {
      existingNo: 'New customer',
      existingYes: 'An account already exists with Jetpak',
      customerTypeCompany: 'Business account',
      customerTypePrivate: 'Private account',
      orgName: 'Company Name',
      orgNumber: 'Company Registration Number',
      vatNumber: 'VAT Number',
      customerNumber: 'Customer Number',
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      cellPhone: 'Mobile Phone',
      phone: 'Phone',
      street: 'Street',
      streetNumber: 'Street Number',
      city: 'City',
      postalCode: 'Postal Code',
      country: 'Country',
    },
    invalidNbr: 'Not a valid account number',
    nonExistantNbr: 'Account number not found',
  },
  orderConfirmationPage: {
    title: 'Booking confirmation',
    preamble: '[Missing content]',
    yourBookingNumber: 'Your booking number (AWB)',
  },
  orderSummary: {
    title: 'Summary',
    preamble: '[Missing content]',
  },
  advise: {
    title: 'Booking and delivery confirmation',
    description:
      'Select if you want your booking confirmation and delivery notifications via text message and/or e-mail',
    type: 'Type',
    method: 'Method',
    bookingConfirmation: 'Booking confirmation',
    deliveryConfirmation: 'Delivery confirmation',
    email: 'E-mail',
    textMessage: 'SMS',
    addMoreNotifications: 'Add more notifications',
  },
  requiredField: 'This field is required',
  timeHasPassed: 'Time has passed',
  invalidEmailAddress:
    'The e-mail address must match the format email@example.com',
  shipper: 'Shipper',
  consignee: 'Consignee',
  shipperAddress: 'Address shipper',
  consigneeAddress: 'Address consignee',
  shipperAddressPlaceholder: 'Search shipper address',
  consigneeAddressPlaceholder: 'Search consignee address',
  pickUpAndDelivery: 'Pickup/Delivery',
  pickUp: 'Pick up time',
  delivery: 'Delivery time',
  earliestPickup: 'Earliest pickup',
  latestDelivery: 'Latest delivery',
  shippingDetails: 'Shipping details',
  select: 'Select',
  selected: 'Selected',
  selectDeliveryOptions: 'Select delivery options',
  price: 'Price',
  date: 'Date',
  time: 'Time',
  package: 'Package',
  add: 'Add',
  addMore: 'Add more',
  remove: 'Remove',
  addPackage: 'Add package',
  removePackage: 'Remove package',
  yourPackages: 'Your packages',
  addMorePackages: 'Add more packages',
  whatIsTheSizeOfYourPackage: 'How large is your package?',
  weight: 'Weight',
  width: 'Width',
  height: 'Height',
  length: 'Length',
  quantity: 'Quantity',
  quantityPcs: 'Number of pieces',
  pcs: 'pcs',
  added: 'Added',
  vat: 'VAT',
  withoutVat: 'excl. VAT',
  withVat: 'incl. VAT',
  total: 'Total',
  clear: 'Clear',
  inKilogram: 'in kilogram',
  discount: 'Discount',
  inCm: 'in cm',
  today: 'Today',
  tomorrow: 'Tomorrow',
  currency: 'Currency',
  countryOfOrigin: 'Country of origin',
  username: 'Username',
  password: 'Password',
  currentPassword: 'Current password',
  newPassword: 'New password',
  newPassword2: 'Repeat password',
  passwordResetLinkCreated: 'A password reset link has been e-mailed to you',
  signIn: 'Sign in',
  signOut: 'Sign out',
  signedIn: 'Signed in',
  signedOut: 'Signed out',
  signInErrorMessage: `We couldn't find your Jetpak Account`,
  forgotPassword: 'Forgot password?',
  resetPassword: 'Reset password',
  changePassword: 'Change password',
  passwordReset: 'Your password has been reset',
  passwordChanged: 'Your password has been changed',
  register: 'Create Account',
  loading: 'Loading',
  company: 'Company',
  name: 'Name',
  phone: 'Phone',
  messageToDriver: 'Message to driver',
  messageToDriverExample: 'Port code, other information ...',
  paymentProcessing: 'Please wait while we are processing your request',
  invoice: 'Invoice',
  creditcard: 'Credit card',
  creditCardHoldersName: "Credit card holder's name",
  email: 'E-mail',
  country: 'Country',
  postalAddress: 'Postal address',
  postalCode: 'Postal code',
  postalArea: 'City',
  open: 'Open',
  close: 'Close',
  or: 'or',
  help: 'Help',
  helpPage: {
    getSupportTicket: 'Create support ticket',
    content1:
      'If you are having issues with your booking, create a support ticket.',
    content2: 'Call our service center and provide this ticket number.',
    supportNumber: 'Support nummer:',
    yourTicketNumber: 'Your ticket number is',
  },
  welcome: 'Welcome',
  youAreloggedInAs: "You're logged in as",
  profile: 'Profile',
  carbonDioxideMessage: 'Preliminary carbon dioxide emissions',
  createNewBooking: 'Create new booking',
  addressBook: 'Address book',
  addressBookModal: {
    title: 'Address book',
    pickAddress: 'Select an address below:',
    noSavedAddresses: 'There are no saved addresses.',
    name: 'Name',
    street: 'Street',
    streetNumber: 'Street Number',
    city: 'City',
    postalCode: 'Postal Code',
    country: 'Country',
    newAddress: 'New address',
    addressName: 'Address name',
    addAnother: 'Add another address',
    addressSaved: 'The address has been saved',
    editAddress: 'Edit address',
    showing: (showing, total) =>
      `Showing ${showing} of ${total} saved addresses`,
  },
  save: 'Save',
  addressValidationErrors: {
    missingStreet: 'Missing street address',
    missingStreetNumber: 'Missing street number',
    verificationError: 'The address could not be verified',
    error: 'Address could not be verified',
  },
  orderFailed: {
    cancelled: 'Payment cancelled',
    error: 'Error creating order',
    declined: 'Your card was declined',
    pickupMissed:
      'Pick up time has passed. You need to search for new transport alternatives.',
  },
  support: {
    needHelp: 'Do you need help?',
    contactOurSupport: 'Contact our support.',
  },
  // parcelTypes: {
  //   envelope: 'Envelope',
  //   bag: 'Bag',
  //   smallBox: 'Small box',
  //   largeBox: 'Large box',
  //   pallet: 'Pallet',
  //   other: 'Other',
  // },
  unitTypes: {
    kg: 'kg',
    cm: 'cm',
  },
  termsAndPolicies: {
    dangerousGoods: {
      label: 'I have read and understood the restrictions for',
      urlLabel: 'transporting dangerous goods',
      url: 'https://jetpak.com/en/conditions/important-information/dangerous-goods/',
    },
    privacyPolicy: {
      label: `I have read and understood Jetpak's`,
      urlLabel: 'Privacy Statement',
      url: 'https://jetpak.com/en/conditions/privacy-statement/',
    },
    termsAndConditions: {
      label: `I agree to Jetpak's`,
      urlLabel: 'Terms & Conditions',
      url: 'https://jetpak.com/en/conditions/terms-and-conditions/',
    },
  },
  days: {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
  },
  insurance: {
    title: 'Insurance',
    description:
      'To insure your goods, please specify the value to calculate the price. The price will be added to your order.',
    labelToggle: 'Add insurance',
    labelInput: 'Insurance value',
    fee: 'Insurance fee',
    insurancePremium: (rate, minPrice, currency) =>
      `The insurance premium is ${rate}% of economic value of goods, minimum ${minPrice} ${currency}. No additional excess fee.`,
  },
};
export default en;

import { Translations } from './TranslationType';
import { Currency } from './../enums/Currency';
const fi: Translations = {
  meta: {
    title: 'Jetpak',
    metaDescription: '',
    appName: 'Lähetä paketti',
    language: 'Suomi',
    languageCode: 'fi-FI',
    currency: Currency.EUR,
    email: 'asiakaspalvelu@jetpak.fi',
    phone: {
      label: '+358 9 7277 180',
      number: '+358 9 7277 180',
    },
  },
  packageTypes: { error: 'Error loading package types' },
  deliveryPage: {
    title: 'Toimitusvaihtoehdot',
    tab: 'Toimitusvaihtoehdot',
    nextStep: 'Jatka paketin sisältöön',
    addressTitle: 'Syötä tiedot',
    preamble: '',
    fillInTheFieldsAboveToSeeDeliveryAlternatives:
      'Täytä yllä olevat kentät nähdäksesi toimitusvaihtoehdot.',
    newAlternativesLoaded: 'Uudet vaihtoehdot saatavilla',
    loadingAlternatives: 'Ladataan toimitusvaihtoehtoja',
    errorLoadingAlternatives: 'Virhe ladattaessa vaihtoehtoja',
    alternativeSelected: 'Toimitusvaihtoehto valittu',
    noAlternativesFound:
      'Toimitusvaihtoehtoja ei löytynyt, päivitä hakukriteerit ja yritä uudelleen.',
    invalidPackages: 'Virhe paketin mitoissa/painossa',
    excessiveVolume:
      'Toimitus on liian suuri online-varausta varten. Ota yhteyttä asiakaspalveluun',
    excessiveWeight:
      'Toimitus on liian painava online-varausta varten. Ota yhteyttä asiakaspalveluun',
    selectDeliveryOptions: 'Valitse toimitusvaihtoehto',
  },
  packageInformationPage: {
    title: 'Pakkauksen sisältö',
    tab: 'Pakkauksen sisältö',
    previousStep: 'Palaa takaisin',
    nextStep: 'Jatka lähettäjään ja vastaanottajaan',
    preamble: '',
    titleContentExample: 'Kuvaile sisältöäsi seuraavalla tavalla',
    packageContentLabel: 'Kuvaile pakkauksen sisältöä',
    placeHolderExample: 'Kuulokkeet, matkapuhelin ja kirja...',
    seeExamples: 'Katso esimerkkejä',
    writeLike: 'Tämä on ok',
    dontWrite: 'Älä kirjoita',
    howToWrite: [
      {
        correct: 'Kuulokkeet ja matkapuhelin',
        incorrect: 'Elektroniikka',
      },
      {
        correct: 'Suodatin, tiivisteet, DC-kaapeli..',
        incorrect: 'Varaosat',
      },
      {
        correct: 'Passi, kirjat..',
        incorrect: 'Paperit',
      },
      {
        correct: 'Leikkikalut, korut..',
        incorrect: 'Lahjat',
      },
    ],
  },
  senderAndConsigneePage: {
    title: 'Lähettäjä Ja Vastaanottaja',
    tab: 'Lähettäjä Ja Vastaanottaja',
    previousStep: 'Palaa takaisin',
    nextStep: 'Jatka yhteenvetoon',
    nextStepCustoms: 'Jatka tullaustietoihin',
    preamble: 'Lisätietoja lähettäjälle ja vastaanottajalle',
  },
  customsPage: {
    title: 'Tullitiedot',
    tab: 'Tulli',
    previousStep: 'Palaa takaisin',
    nextStep: 'Jatka yhteenvetoon',
    preamble:
      'Yritykselläsi on oltava EORI-numero, jotta voit tuoda ja viedä tavaroita (EORI = taloudellisen toimijan rekisteröinti ja tunnistetiedot).',
    eori: {
      linkText: 'Lue lisää EORI-numeroista',
      linkUrl: 'https://tulli.fi/asiointi-info/eori',
      description: '',
      title: 'EORI',
    },
    customsValue: 'Tullausarvo',
    customsValueDescription:
      'Tullausarvo perustuu tavaroiden toimittajien hintaan. Tiedot muista kustannuksista (lukuun ottamatta tavaroiden kustannuksia), jotka sisältyvät tullausarvoon, ovat saatavissa osoitteesta ',
    customsValueLinkText: '[https://tulli.fi]',
    customsValueLinkUrl: 'https://tulli.fi/yritysasiakkaat/tuonti/tullausarvo',
    customsInvoiceNumber: 'Kauppa- tai proformalaskun numero',
    customsInvoiceNumberDescription:
      'Lähetyksen mukana on oltava lasku. Jos sisältö on tarkoitus myydä vastaanottavassa maassa, sinun tulee käyttää kauppalaskua. Jos tuotetta ei ole tarkoitus myydä, käytä proforma-laskua.',
    customsInvoiceNumberLinkDescription: 'Lataukset:',
    customsInvoiceNumberLinkText1: 'Kauppalasku',
    customsInvoiceNumberLinkUrl1:
      'https://jetpak.com/files/5714/8613/0709/Jetpak_Commercial_Invoice.pdf',
    customsInvoiceNumberLinkText2: 'Proforma-lasku',
    customsInvoiceNumberLinkUrl2:
      'https://jetpak.com/files/8214/8613/0638/Jetpak_Proforma_Invoice.pdf',
    customsNumber: 'Tullinimike',
    customsNumberDescription1:
      'Tullinimike päättää, mitä tullitariffia käytetään tuotteessa, ja se koostuu kymmennumeroisesta numerosta. Voit etsiä tavaranimikkeen otsikon alla olevasta tulliviranomaisten verkkosivuilta tullinimike.',
    customsNumberDescription2:
      'Merkkaa lähetyksen laskuun oikea tullinimike sitä vastaavaan kenttään.',
    customsContent: '',
    shipperEori: 'Eori shipper',
    consigneeEori: 'Eori consignee',
    commodityCodeLength: 'Minimum 6 characters',
  },
  paymentPage: {
    title: 'Yhteenveto',
    tab: 'Yhteenveto',
    preamble: 'Kiitos varauksesta! Tässä on sinun varausvahvistus',
    previousStep: 'Palaa takaisin',
    previousStepCustoms: 'Palaa takaisin tullitietoihin',
    nextStepByCard: 'Jatka maksuun',
    nextStepByInvoice: 'Maksa laskulla',
    paymentOptions: 'Maksutavat',
    paymentReference: 'Maksuviite',
    paymentReferencePlaceholder: 'Oma viitteesi tai ostotilausnumero',
    signIntoPayByInvoice:
      'Sinun täytyy kirjautua sisään, jotta voit maksaa laskulla.',
    invoiceNotAllowed:
      'Maksu laskulla ei ole tällä hetkellä sallittu. Ota yhteyttä asiakaspalveluun saadaksesi lisätietoja.',
    campaignCode: 'Kampanjakoodi',
    campaignCodeApplied: 'Kampanjakoodi lisätty',
    invalidCampaignCode: 'Väärä kampanjakoodi',
    apply: 'Käytä kampanjakoodia',
  },
  loginPage: {
    title: 'Kirjaudu sisään',
  },
  registerPage: {
    title: 'Luo tili',
    buttonText: 'Luo Tili',
    continueButtonText: 'Jatka',
    previousButtonText: 'Takaisin',
    customerTypeHeader: 'Haluan',
    existingCustomerHeader:
      'Onko yritykselläsi jo olemassa oleva asiakasnumero?',
    loginText: 'Onko sinulla käyttäjätili?',
    fields: {
      existingNo: 'Uusi asiakas',
      existingYes: 'Asiakasnumero on jo olemassa',
      customerTypeCompany: 'Yritystili',
      customerTypePrivate: 'Yksityistili',
      orgName: 'Yrityksen nimi',
      orgNumber: 'Yrityksen Y-tunnus',
      vatNumber: 'ALV-numero',
      customerNumber: 'Asiakasnumero',
      firstName: 'Etunimi',
      lastName: 'Sukunimi',
      email: 'Sähköposti',
      cellPhone: 'Matkapuhelin',
      phone: 'Puhelin',
      street: 'Osoite',
      streetNumber: 'Nro',
      city: 'Postitoimipaikka',
      postalCode: 'Postinumero',
      country: 'Maa',
    },
    invalidNbr: 'Asiakasnumero ei ole käytössä',
    nonExistantNbr: 'Asiakasnumero ei löytynyt',
  },
  orderConfirmationPage: {
    title: 'Varausvahvistus',
    preamble: 'Kiitos varauksesta! Tässä on sinun varausvahvistus',
    yourBookingNumber: 'Varausnumero (AWB)',
  },
  orderSummary: {
    title: 'Yhteenveto',
    preamble: 'Varauksen esikatselu',
  },
  advise: {
    title: 'Varaus- ja toimitusvahvistus',
    description:
      'Valitse, jos haluat varaus- ja toimitusvahvistuksen sähköpostilla ja/tai tekstiviestillä',
    type: 'Tyyppi',
    method: 'Tapa',
    bookingConfirmation: 'Varausvahvistus',
    deliveryConfirmation: 'Toimitusvahvistus',
    email: 'Sähköposti',
    textMessage: 'SMS',
    addMoreNotifications: 'Lisää ilmoituksia',
  },
  requiredField: 'Tämä kenttä on pakollinen',
  timeHasPassed: 'Aika on kulunut',
  invalidEmailAddress:
    'Sähköpostiosoitteen on vastattava muotoa email@example.com',
  shipper: 'Lähettäjä',
  consignee: 'Vastaanottaja',
  shipperAddress: 'Lähettäjän osoite',
  consigneeAddress: 'Vastaanottajan osoite',
  shipperAddressPlaceholder: 'Hae lähettäjän osoite',
  consigneeAddressPlaceholder: 'Hae vastaanottajan osoite',
  pickUpAndDelivery: 'Nouto/Toimitus',
  pickUp: 'Noutoaika',
  delivery: 'Toimitusaika',
  earliestPickup: 'Aikaisin nouto',
  latestDelivery: 'Viimeisin toimitus',
  shippingDetails: 'Lähetyksen tiedot',
  select: 'Valitse',
  selected: 'Valittu',
  selectDeliveryOptions: 'Valitse toimitusvaihtoehdot',
  price: 'Hinta',
  date: 'Päivämäärä',
  time: 'Aika',
  package: 'Paketti',
  add: 'Lisää',
  addMore: 'Lisää uusi',
  remove: 'Poista',
  addPackage: 'Lisää paketti',
  removePackage: 'Poista paketti',
  yourPackages: 'Pakettisi',
  addMorePackages: 'Lisää paketteja',
  whatIsTheSizeOfYourPackage: 'Kuinka suuri pakettisi on?',
  weight: 'Paino',
  width: 'Leveys',
  height: 'Korkeus',
  length: 'Pituus',
  quantity: 'Määrä',
  quantityPcs: 'Kappaleiden lukumäärä',
  pcs: 'kpl',
  added: 'Lisätty',
  vat: 'ALV',
  withoutVat: 'Ilman ALV:tä',
  withVat: 'Sisältäen ALV:n',
  total: 'Yhteensä',
  clear: 'Tyhjennä',
  inKilogram: 'kilogrammoissa',
  inCm: 'senttimetreinä',
  today: 'Tänään',
  discount: 'Alennus',
  tomorrow: 'Huomenna',
  currency: 'Valuutta',
  countryOfOrigin: 'Alkuperämaa',
  username: 'Käyttäjänimi',
  currentPassword: 'Nykyinen salasana',
  password: 'Salasana',
  changePassword: 'Muuta salasana',
  newPassword: 'Uusi salasana',
  newPassword2: 'Toista salasana',
  passwordResetLinkCreated:
    'Salasanan palautuslinkki on lähetetty sähköpostiisi',
  signIn: 'Kirjaudu sisään',
  signOut: 'Kirjaudu ulos',
  signedIn: 'Kirjautuneena sisään',
  signedOut: 'Kirjauduttu ulos',
  signInErrorMessage: `Emme löytäneet Jetpak-tiliäsi
`,
  forgotPassword: 'Unohditko salasanasi?',
  resetPassword: 'Reset password',
  passwordReset: 'Salasanasi on vaihdettu',
  passwordChanged: 'Salasanasi on vaihdettu',
  register: 'Luo Tili',
  loading: 'Ladataan',
  company: 'Yritys',
  name: 'Nimi',
  phone: 'Puhelin',
  messageToDriver: 'Viesti kuljettajalle',
  messageToDriverExample: 'Porttikoodi, muita tietoja...',
  paymentProcessing: 'Ole hyvä ja odota kun käsittelemme pyyntöäsi',
  invoice: 'Lasku',
  creditcard: 'Luottokortti',
  creditCardHoldersName: 'Luottokortin haltijan nimi',
  email: 'Sähköposti',
  country: 'Maa',
  postalAddress: 'Osoite',
  postalCode: 'Postinumero',
  postalArea: 'Postitoimipaikka',
  open: 'Avaa',
  close: 'Sulje',
  or: 'tai',
  help: 'Apua',
  helpPage: {
    getSupportTicket: 'Luo tukipyyntö',
    content1: 'Jos sinulla on vaikeuksia varauksesi kanssa, luo tukipyyntö.',
    content2: 'Soita asiakaspalveluumme ja anna tukipyynnön numero.',
    supportNumber: 'Asiakaspalvelun numero:',
    yourTicketNumber: 'Tukinumerosi on ',
  },
  welcome: 'Tervetuloa',
  profile: 'Profiili',
  youAreloggedInAs: 'Olet kirjautunut sisään nimellä',
  carbonDioxideMessage: 'Alustavat hiilidioksidipäästöt',
  createNewBooking: 'Tee uusi varaus',
  addressBook: 'Osoitekirja',
  addressBookModal: {
    title: 'Osoitekirja',
    pickAddress: 'Valitse osoite',
    noSavedAddresses: 'Ei tallennettuja osoitteita.',
    name: 'Nimi',
    street: 'Osoite',
    streetNumber: 'Nro',
    city: 'Postitoimipaikka',
    postalCode: 'Postinumero',
    country: 'Maa',
    addAnother: 'Add another',
    addressName: 'Address name',
    addressSaved: 'Tallennettu',
    newAddress: 'Uusi osoite',
    editAddress: 'Muokkaa osoitetta',
    showing: (showing, total) =>
      `Näytetään ${showing} tallennettua osoitetta ${total}:sta`,
  },
  addressValidationErrors: {
    missingStreet: 'Puuttuva osoite',
    missingStreetNumber: 'Puuttuva Nro',
    verificationError: 'Osoitetta ei voitu vahvistaa',
    error: 'Osoitetta ei voitu vahvistaa',
  },
  orderFailed: {
    cancelled: 'Maksu peruttu',
    error: 'Varaus epäonnistui',
    declined: 'Korttisi hylättiin',
    pickupMissed:
      'Noutoaika ohitettu. Ole hyvä ja etsi uusia toimitusvaihtoehtoja.',
  },
  support: {
    needHelp: 'Tarvitsetko apua?',
    contactOurSupport: 'Ota yhteyttä asiakaspalveluumme',
  },
  // parcelTypes: {
  //   envelope: 'Kirjekuori',
  //   bag: 'Pussi',
  //   smallBox: 'Pieni laatikko',
  //   largeBox: 'Suuri laatikko',
  //   pallet: 'Lava',
  //   other: 'Muu',
  // },
  unitTypes: {
    kg: 'kg',
    cm: 'cm',
  },
  termsAndPolicies: {
    dangerousGoods: {
      label: 'Olen lukenut ja ymmärtänyt rajoitukset liittyen',
      urlLabel: 'vaarallisten aineiden kuljettamiseen',
      url: 'https://jetpak.com/fi/ehdot/tarkeaa-tietoa/vaaralliset-aineet/',
    },
    privacyPolicy: {
      label: `Olen lukenut ja ymmärtänyt Jetpakin
`,
      urlLabel: 'Tietosuojaselosteen',
      url: 'https://jetpak.com/fi/ehdot/tietosuojaseloste/',
    },
    termsAndConditions: {
      label: `Hyväksyn Jetpakin
`,
      urlLabel: 'Kuljetusehdot',
      url: 'https://jetpak.com/fi/ehdot/kuljetusehdot/',
    },
  },
  days: {
    monday: 'Maanantai',
    tuesday: 'Tiistai',
    wednesday: 'Keskiviikko',
    thursday: 'Torstai',
    friday: 'Perjantai',
    saturday: 'Lauantai',
    sunday: 'Sunnuntai',
  },
  insurance: {
    title: 'Vakuutus',
    description:
      'Ole hyvä ja määritä lähetyksen arvo vakuutusta varten. Vakuutuksen hinta lisätään varaukseesi.',
    labelToggle: 'Lisää vakuutus',
    labelInput: 'Lähetyksen arvo',
    fee: 'Vakuutusmaksu',
    insurancePremium: (rate, minPrice, currency) =>
      `Vakuutusmaksu on ${rate}% tuotteen arvosta, mutta vähintään ${minPrice} ${currency}. Omavastuukuluja ei lisätä.`,
  },
  save: 'Save',
};
export default fi;
